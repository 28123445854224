import React from "react";
import PropTypes from "prop-types";
import styles from "./PatientTile.module.scss";
import classnames from "classnames";
import get from "lodash/get";
import SpeciesIcon from "./SpeciesIcon";

export default function PatientTile(props) {
  const { species, patientName, offers } = props.patient;
  const numberOfAvailableOffers = get(offers, "length", 0);


  return (
    <button
      className={classnames("et-btn", styles.root, {[styles.small]: props.small}, {[styles.selected]: props.selected})}
      onClick={props.onClick}
    >
      <SpeciesIcon
        speciesType={species}
        className="fa-6x padding-sm"
      />
      <div className={classnames("padding-sm", styles.tileText)}>
        {patientName}
      </div>
      {!props.hideOffers && (
        <div className={classnames("padding-sm text-italic", styles.tileText)}>
          {numberOfAvailableOffers} Offer{numberOfAvailableOffers === 1 ? "" : "s"} Available
        </div>
      )}
    </button>
  );
}

PatientTile.propTypes = {
  patient: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  hideOffers: PropTypes.bool,
  small: PropTypes.bool,
  selected: PropTypes.bool,
};

PatientTile.defaultProps = {
  hideOffers: false,
  small: false,
  selected: false,
};
