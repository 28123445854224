import React, {useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import styles from "./SpinningWheel.module.scss";
import {eventListeners} from "@popperjs/core";
import PropTypes from "prop-types";

const WHEEL_IMG = require("../../images/SpinnerWheel.png");
const WHEEL_SOUND = require("../../audio/spin_wheel.mp3");

// const ROTATION_DEG = 1620;
const ROTATION_DEG = 2700;

function SpinningWheel(props) {
    const audioRef = useRef();
    const imageRef = useRef();
    const [audioReady, setAudioReady] = useState(false);
    const [imageReady, setImageReady] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentWheelDeg, setCurrentWheelDeg] = useState(180);

    useEffect(() => {
        if (audioReady && imageReady && audioRef.current && imageRef.current) {
            startAnimation();
        }
    },[audioRef.current, imageRef.current, audioReady, imageReady]);

    useEffect(() => {
        if (audioRef.current) {
            setAudioReady(true);
        }
    }, [audioRef.current]);

    const currentWheelRotation = useMemo(() => {
        return `rotate(${currentWheelDeg}deg)`;
    }, [currentWheelDeg]);
    const startAnimation = () => {
        audioRef.current.play();
        const newRotation = currentWheelDeg + ROTATION_DEG;
        setIsAnimating(true);
        setCurrentWheelDeg(newRotation);
        setTimeout(handleEndAnimation, 3000);
    }

    const handleEndAnimation = () => {
        setIsAnimating(false);
        setTimeout(props.onCompleted, 350);
    }

    const audioFile = useMemo(() => {
        return (
            <audio
                src={WHEEL_SOUND}
                ref={audioRef}
            />
        );
    }, []);
    return (
        <div className={styles.root}>
            <div className={classnames(styles.wheelContainer, {
                [styles.animate]: isAnimating,
            })}>
                <img
                    className={styles.wheelImg}
                    alt="spinning prize wheel"
                    src={WHEEL_IMG}
                    onLoad={() => setImageReady(true)}
                    ref={imageRef}
                    style={{
                        transform: currentWheelRotation,
                    }}
                />
            </div>
            {audioFile}
        </div>
    );
}

SpinningWheel.propTypes = {
    onCompleted: PropTypes.func,
}


export default SpinningWheel;
