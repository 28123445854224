
export default function getSurveyModalHeaderText(appState) {
    if (appState.isLoading) {
        return "Loading...";
    }

    if (appState.hasCompletedSurvey) {
        return "Thank you!";
    }

    return "Please Take a Few Moments to Complete This Survey";
}
