import React from "react";
import PropTypes from "prop-types";

export default function AddPetFormSubmittedDisplay(props) {
  return (
    <>
        <p>Thank you for adding your pet. Your pet's information has been submitted.</p>
        <p>Click below to view the available offers which you can use at your clinic today.</p>
        <div className="text-center">
            <button
                className="et-btn"
                onClick={props.onViewAllOffersClicked}
            >
                View All Offers
            </button>
        </div>
    </>
  );
}

AddPetFormSubmittedDisplay.propTypes = {
    onViewAllOffersClicked: PropTypes.func.isRequired,
};
