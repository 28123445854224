import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CANINE, FELINE } from "../constants/SpeciesTypes";

export default function SpeciesIcon(props) {
  const speciesIconClassname = props.speciesType === CANINE ? "fa-dog" : "fa-cat";
  return (
      <i className={classnames(`fas ${speciesIconClassname}`, props.className)} />
  );
}

SpeciesIcon.propTypes = {
  speciesType: PropTypes.oneOf([CANINE, FELINE]).isRequired,
  className: PropTypes.string,
};
