import React from "react";
import PropTypes from "prop-types";
import styles from "./LoadingSpinner.module.scss";
import classnames from "classnames";

const SPINNER_ICON_CLASS = "fas fa-circle-notch";

export default function LoadingSpinner(props) {
    if (!props.show) {
        // Render the spinner icon behind everything, to "preload" the icon
        return (
            <i
                className={SPINNER_ICON_CLASS}
                style={{
                    position: "absolute",
                    zIndex: -1,
                }}
            />
        );
    }

    return (
        <div className={styles.loadingSpinnerRoot}>
            <div className={styles.spinner}>
                <i className={SPINNER_ICON_CLASS} />
            </div>

            {!!props.loadingMessage && (
                <div className={classnames("text-lg", styles.message)}>
                    {props.loadingMessage}
                </div>
            )}
        </div>
    );
}

LoadingSpinner.propTypes = {
    show: PropTypes.bool,
    loadingMessage: PropTypes.string,
};
