import React from "react";
import styles from "./Header.module.scss";

const GREENLINE_LOGO_SRC = require("../images/greenline-horizontal-logo.png");

export default function Header(props) {
  return (
    <div className={styles.root}>
      <img
        src={GREENLINE_LOGO_SRC}
        className={styles.logo}
        alt="GreenlineLogo"
      />
    </div>
  );
}
