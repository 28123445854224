import React, { useEffect, useState } from "react";
import styles from "./CouponLibraryPage.module.scss";
import classnames from "classnames";
import OffersAccordion from "../components/OffersAccordion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import WatermarkBackground from "../components/WatermarkBackground";
import SpeciesIcon from "../components/SpeciesIcon";
import * as requests from "../utils/requests";
import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import SafetyInfoFooter from "../components/SafetyInfoFooter";
import OfferImage from "../components/OfferImage";
import { OFFER_ORDER_FIELD, OFFER_GROUP_ORDER_FIELD } from "../constants/OrderFields";
import SelectedOfferDetails from "../components/SelectedOfferDetails";
import AppError from "../components/AppError";
import Modal from "../components/Modal";
import RequestPetAdditionForm from "../forms/RequestPetAdditionForm";
import AddPetFormSubmittedDisplay from "../components/AddPetFormSubmittedDisplay";

export default function CouponLibraryPage({ appState, onAppStateChanged }) {
    const [showError, setShowError] = useState(false);
    const [showAllOffers, setShowAllOffers] = useState(false);
    const [selectedOfferData, setSelectedOfferData] = useState(null);
    const [patientData, setPatientData] = useState(null);
    const [nationalOffers, setNationalOffers] = useState(null);
    const [showAddPetModal, setShowAddPetModal] = useState(false);
    const [hasSubmittedAddPetForm, setHasSubmittedAddPetForm] = useState(false);
    const { petOwnerId } = useParams();
    const clinicGuid = localStorage.getItem("clinicGuid");
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const patientId = searchParams.get("patientId");
    const showAllOffersSearchParam = Boolean(searchParams.get("showAllOffers") || false);

    useEffect(() => {
        handleLoadOffers();
    }, [petOwnerId]);

    const handleLoadOffers = async () => {
        // Show the spinner
        onAppStateChanged({
            isLoading: true,
        });

        try {
            if (patientId) {
                // Load all the client offers and save the patient data
                const clientOffersResponse = await requests.loadClientOffers();
                if (clientOffersResponse.Status && clientOffersResponse.Status === 500) {
                    throw new Error("Error loading client offers: ", clientOffersResponse.Detail);
                }

                const patientsList = keyBy(clientOffersResponse.patientOffers, "patientId");
                setPatientData(patientsList[patientId]);
            }

            if (!patientId || showAllOffersSearchParam) {
                // Client doesn't have any patient offers or the search param to show all offers is true; automatically show the national offers instead
                setShowAllOffers(true);
            }

            // Then load the national offers
            const nationalOffersResponse = await requests.loadNationalOffersForClinic();
            if (nationalOffersResponse.Status && nationalOffersResponse.Status === 500) {
                throw new Error("Error loading national offers: ", nationalOffersResponse.Detail);
            }
            setNationalOffers(orderBy(nationalOffersResponse, OFFER_GROUP_ORDER_FIELD));
        } catch (e) {
            console.error(e);
            setShowError(true);
        } finally {
            onAppStateChanged({
                isLoading: false,
            });
        }
    };

    const closeAddPetModal = () => {
        setShowAddPetModal(false);
        setHasSubmittedAddPetForm(false);
    };

    const handleViewAllOffersFromAddPetModal = () => {
        closeAddPetModal();
        setShowAllOffers(true);
    };

    if ((patientId && !patientData) || !nationalOffers) {
        if (showError) {
            return (
                <AppError
                    errorMessage="Uh oh! There was an error loading your offers. Please try again later."
                />
            );
        }
        return null;
    }

    return (
        <WatermarkBackground>
            <div className="flex-1 flex-column height-100">
                {!!selectedOfferData ? (
                    <SelectedOfferDetails
                        onBackButtonClicked={() => setSelectedOfferData(null)}
                        selectedOfferData={selectedOfferData}
                        appState={appState}
                        onAppStateChanged={onAppStateChanged}
                    />
                ) : (
                    <div className={styles.couponLibraryPageContent}>
                        <div className={classnames(styles.banner, {[styles.bigPadding]: !!patientId})}>
                            {patientId ? (
                                <span className={styles.patientName}>{patientData.patientName}</span>
                            ) : (
                                <div className="flex-1 flex-row flex-center flex-justify-space-between">
                                    <span className={classnames(styles.addPetPrompt, styles.subheaderText)}>Add your pet to receive personalized offers</span>

                                    <div className="flex-none">
                                        <button
                                            className={classnames("et-btn")}
                                            onClick={() => setShowAddPetModal(true)}
                                        >
                                            Add Pet
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={styles.mainContent}>
                            <div className={classnames("flex-row", styles.subheader)}>
                                {showAllOffers ? (
                                    <>
                                        <button
                                            className={classnames("et-btn", styles.backButton, {[styles.hidden]: !patientId})}
                                            onClick={patientId ? () => setShowAllOffers(false) : null}
                                        >
                                            <i className="fas fa-chevron-left" /> Back
                                        </button>

                                        <div className={classnames(styles.subheaderText, {[styles.absoluteCentered]: !patientId})}>All Available Offers</div>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className={classnames("et-btn", styles.backButton)}
                                            onClick={() => navigate(`/app/clinic/${clinicGuid}/user/${petOwnerId}`)}
                                        >
                                            <i className="fas fa-chevron-left" /> Back to Pets
                                        </button>

                                        <div className={styles.subheaderText}>{patientData.patientName}'s Offers</div>
                                    </>
                                )}
                            </div>

                            {showAllOffers ? (
                                <div className={classnames("flex-column flex-1 flex-justify-space-between", styles.accordionContainer)}>
                                    <OffersAccordion
                                        offers={nationalOffers}
                                        onOfferSelected={setSelectedOfferData}
                                    />
                                </div>
                            ) : (
                                <div className={styles.featuredOffersContainer}>
                                    <div className={classnames("text-left text-dark-gray", styles.featuredOffersText)}>
                                        Featured Offers
                                    </div>
                                    <div className="spaced-content-vertical">
                                        {orderBy(patientData.offers, OFFER_ORDER_FIELD).map(offer => (
                                            <div key={`product-image-${offer.couponLibraryOptionOfferId}`}>
                                                <OfferImage
                                                    imageSrc={offer.clientDisplayImageFileName}
                                                    altText={offer.sidebarTitle}
                                                    onClick={() => setSelectedOfferData(offer)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="text-sm spaced-content-vertical margin-top-lg">
                                        <div className={classnames("link", styles.somethingElseText)}>
                                            Looking for something else?
                                        </div>
                                        <button
                                            className="et-btn"
                                            onClick={() => setShowAllOffers(true)}
                                        >
                                            View All Offers
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <Modal
                show={showAddPetModal}
                headerText={hasSubmittedAddPetForm ? "Information Submitted" : "Manage Your Pets"}
                onClose={closeAddPetModal}
                showFullCloseButton={false}
            >
                {hasSubmittedAddPetForm ? (
                    <div>
                        <AddPetFormSubmittedDisplay
                            onViewAllOffersClicked={handleViewAllOffersFromAddPetModal}
                        />
                    </div>
                ) : (
                    <RequestPetAdditionForm
                        clinicGuid={clinicGuid}
                        onAfterSubmit={() => setHasSubmittedAddPetForm(true)}
                        onAppStateChanged={onAppStateChanged}
                    />
                )}
            </Modal>
        </WatermarkBackground>
    );
}
