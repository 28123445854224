import React from "react";
import PropTypes from "prop-types";
import styles from "./AppError.module.scss";

export default function AppError(props) {
  return (
    <div className={styles.root}>
      {props.errorMessage}
    </div>
  );
}

AppError.propTypes = {
  errorMessage: PropTypes.string,
};

AppError.defaultProps = {
  errorMessage: "There was an error. Please try again later.",
};
