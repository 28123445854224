import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./SelectedOfferDetails.module.scss";
import classnames from "classnames";
import * as requests from "../utils/requests";
import get from "lodash/get";

import FancyGreenBgSvg from "../images/fancy-green-bg.svg";
import Modal from "./Modal";

const SHOW_SURVEY_TIMEOUT_MS = 60000; // Automatically show the survey 1 minute after showing the offer details

export default function SelectedOfferDetails(props) {
  const [showSelectedOffer, setShowSelectedOffer] = useState(false);
  const [shouldShowSurveyAfterViewingOffer, setShowSurveyAfterViewingOffer] = useState(false);

  useEffect(() => {
    handleLoadAvailableSurvey();
  }, []);

  const handleLoadAvailableSurvey = async () => {
    // Show the spinner
    props.onAppStateChanged({
      isLoading: true,
    });

    try {
      const surveyResponse = await requests.loadSurvey();
      if (surveyResponse.Status && surveyResponse.Status === 500) {
          throw new Error("Error loading survey: ", surveyResponse.Detail);
      }

      const firstSurvey = surveyResponse[0];
      const questions = get(firstSurvey, "clientSurveyQuestions", []);

      const hasAvailableSurvey = questions.length > 0;
      setShowSurveyAfterViewingOffer(hasAvailableSurvey);
    } catch (e) {
      console.error(e);
    } finally {
      props.onAppStateChanged({
        isLoading: false,
      });
    }
  };

  const handleShowSelectedOffer = () => {
    setShowSelectedOffer(true);

    if (shouldShowSurveyAfterViewingOffer) {
      setTimeout(() => {
        props.onAppStateChanged({
          showSurvey: true,
        });
      }, SHOW_SURVEY_TIMEOUT_MS);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.backToOffersButton}>
          <button
              className="et-btn"
              onClick={props.onBackButtonClicked}
          >
              <i className="fas fa-chevron-left" /> Back to Offers
          </button>
      </div>

      <div className={styles.offerDetailsContainer}>
          <div className={styles.offerDetails}>
              <span
                  className={classnames(styles.offerText, {[styles.blurry]: !showSelectedOffer})}
                  dangerouslySetInnerHTML={{ __html: props.selectedOfferData.displayDescription }}
              />
          </div>
      </div>

      <div className={classnames(styles.offerFooter, {[styles.blurry]: !showSelectedOffer})}>
        <span dangerouslySetInnerHTML={{ __html: props.selectedOfferData.displayLegalLine }} />
      </div>

      <Modal
        show={!showSelectedOffer}
        onClose={handleShowSelectedOffer}
        customContent={(
          <div
            className={styles.showSelectedOfferOverlay}
            style={{ backgroundImage: `url(${FancyGreenBgSvg})` }}
          >
            <div className={styles.prompt}>
                Show This To Your Clinic's Front Desk Staff To Redeem At Checkout
            </div>

            <button
                className="et-btn basic small"
                onClick={handleShowSelectedOffer}
            >
                Show Offer
            </button>
          </div>
        )}
      />
    </div>
  );
}

SelectedOfferDetails.propTypes = {
  onBackButtonClicked: PropTypes.func.isRequired,
  selectedOfferData: PropTypes.object.isRequired,
  appState: PropTypes.object.isRequired,
  onAppStateChanged: PropTypes.func.isRequired,
};

SelectedOfferDetails.defaultProps = {};
