import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./OfferImage.module.scss";

export default function OfferImage(props) {
    return (
        <img
            className={classnames("width-100", styles.root, props.className, {"clickable": !!props.onClick})}
            src={props.imageSrc}
            alt={props.altText}
            onClick={props.onClick}
        />
    );
}

OfferImage.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    altText: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};
