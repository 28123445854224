import React from "react";
import PropTypes from "prop-types";
import { Navigate, useParams } from "react-router-dom";

function AuthenticatedRouteWrapper({ children }) {
    const { clinicId } = useParams();

    // Check local storage that an access token exists and it is not expired
    const accessToken = localStorage.getItem("accessToken");
    const accessTokenExpirationMs = localStorage.getItem("accessTokenExpirationMs");
    const currentTimeMs = Date.now();
    const isAccessTokenExpired = !!accessTokenExpirationMs && (currentTimeMs > Number(accessTokenExpirationMs));
    const isAuthenticated = !!accessToken && !isAccessTokenExpired;

    return isAuthenticated ? (
        children
    ) : (
        <Navigate to={{ pathname: `/app/clinic/${clinicId}` }} />
    );
}

AuthenticatedRouteWrapper.propTypes = {};

export default AuthenticatedRouteWrapper;
