import React from "react";
import styles from "./WatermarkBackground.module.scss";

const BACKGROUND_IMAGE_SRC = require("../images/watermark-background.png");

export default function WatermarkBackground(props) {
    return (
        <div
            className={styles.root}
            style={{
                backgroundImage: `url(${BACKGROUND_IMAGE_SRC})`,
                backgroundRepeat: "no-repeat",
            }}
        >
            {props.children}
        </div>
    );
}
