import React from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.scss";
import classnames from "classnames";

export default function Modal(props) {
    if (!props.show) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div
                className={styles.backdrop}
                onClick={props.onClose}
            />
            {!!props.customContent ? (
                <div className={classnames(styles.modalContainer, styles.custom)}>
                    {props.customContent}
                </div>
            ) : (
                <div className={styles.modalContainer}>
                    <div
                        className={styles.closeButtonIcon}
                        onClick={props.onClose}
                    >
                        <i className="fal fa-times" />
                    </div>
                    <h2>{props.headerText}</h2>
                    <hr />
                    <div className={styles.content}>
                        {props.children}
                    </div>

                    {props.showFullCloseButton && (
                        <div className="text-right">
                            <button
                                className="et-btn basic small"
                                onClick={props.onClose}
                            >
                                Close
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    headerText: PropTypes.string,
    onClose: PropTypes.func,
    showFullCloseButton: PropTypes.bool,
    customContent: PropTypes.node,
};

Modal.defaultProps = {
    showFullCloseButton: true,
};
