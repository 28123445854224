import React from "react";
import PropTypes from "prop-types";
import styles from "./SurveyQuestion.module.scss";
import classnames from "classnames";
import range from "lodash/range";
import get from "lodash/get";
import { STAR_RATING, TEXT_BOX } from "../constants/SurveyQuestionTypes";

const DEFAULT_MIN_STAR_RATING = 0;
const DEFAULT_MAX_STAR_RATING = 5;

export default function SurveyQuestion({ name, value, onChange, questionType, questionConfig }) {

  const handleInputChanged = (e) => {
    e.preventDefault();
    const { value } = e.target;

    onChange({
      name,
      value,
    });
  };

  if (questionType === STAR_RATING) {
    const minStarRating = get(questionConfig, "MinRating", DEFAULT_MIN_STAR_RATING) + 1;
    const maxStarRating = get(questionConfig, "MaxRating", DEFAULT_MAX_STAR_RATING) + 1;
    return (
      <div className={styles.starsContainer}>
        {range(minStarRating, maxStarRating).map(rating => (
            <div
              key={`survey-star-${rating}`}
              onClick={() => onChange({ name, value: rating.toString() })}
              className={classnames(styles.starIcon, {[styles.selected]: rating <= Number(value)})}
            >
              <i className="fas fa-star fa-2x" />
            </div>
          ))}
      </div>
    );
  } else if (questionType === TEXT_BOX) {
    const textBoxMaxLength = get(questionConfig, "MaxCharacters");
    return (
      <div className="position-relative">
        <textarea
          value={value || ""}
          onChange={handleInputChanged}
          maxLength={textBoxMaxLength}
        />

        {textBoxMaxLength !== undefined && (
          <div className={styles.inputSubtext}>
            (Max {textBoxMaxLength} characters)
          </div>
        )}
      </div>
    );
  }

  console.warn(`Question type not supported: ${questionType}`);
  return null;
}

SurveyQuestion.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  questionType: PropTypes.oneOf([STAR_RATING, TEXT_BOX]).isRequired,
  questionConfig: PropTypes.object,
};
