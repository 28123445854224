import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./RequestPetAdditionForm.module.scss";
import classnames from "classnames";
import { CANINE, FELINE } from "../constants/SpeciesTypes";
import * as requests from "../utils/requests";
import AppError from "../components/AppError";

export default function RequestPetAdditionForm(props) {
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [formData, setFormData] = useState({});
  const isFormValid = !!formData.petName && !!formData.species;

  const handleFormDataChanged = ({ name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChanged = (e, name) => {
    e.preventDefault();
    const { value } = e.target;

    handleFormDataChanged({
      name,
      value,
    });
  };

  const handleSubmitForm = async () => {
    // Show the spinner
    props.onAppStateChanged({
      isLoading: true,
    });

    try {
      const { petName, species } = formData;
      const petAdditionResponseStatus = await requests.submitRequestPetAdditionData(props.clinicId, petName, species);
      if (petAdditionResponseStatus === 204) {
        if (!!props.onAfterSubmit) {
          props.onAfterSubmit();
        }
      } else {
        throw new Error("Error submitting form");
      }
    } catch (e) {
      console.error(e);
      setShowSubmitError(true);
    } finally {
      props.onAppStateChanged({
        isLoading: false,
      });
    }
  };

  const checkEnterKeyDown = (e) => {
    if (isFormValid && e.key === "Enter") {
      handleSubmitForm();
    }
  };

  if (showSubmitError) {
    return (
      <AppError
        errorMessage="Uh oh! There was an error submitting your request. Please try again later."
      />
    );
  }

  return (
    <div>
      <h4 className={styles.formHeader}>Missing your pet?</h4>
      <div className={styles.formPrompt}>
        Enter your pet's information
      </div>
      <div className={classnames("form", styles.formContent)}>
        <input
          type="text"
          placeholder="Pet Name"
          value={formData.petName || ""}
          onChange={(e) => handleInputChanged(e, "petName")}
          onKeyDown={checkEnterKeyDown}
          maxLength={100}
        />

        <select
          value={formData.species || ""}
          onChange={(e) => handleInputChanged(e, "species")}
          onKeyDown={checkEnterKeyDown}
        >
          <option value="">-- Select a Species --</option>
          <option value={CANINE}>{CANINE}</option>
          <option value={FELINE}>{FELINE}</option>
        </select>

        <div className="text-right">
          <button
            className="et-btn small"
            onClick={handleSubmitForm}
            disabled={!isFormValid}
          >
            Submit Information
          </button>
        </div>
      </div>
    </div>
  );
}

RequestPetAdditionForm.propTypes = {
  clinicId: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func,
  onAppStateChanged: PropTypes.func.isRequired,
};
