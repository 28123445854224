import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./NotYourPetForm.module.scss";
import classnames from "classnames";
import PatientTile from "../components/PatientTile";
import * as requests from "../utils/requests";
import AppError from "../components/AppError";
import find from "lodash/find";

export default function NotYourPetForm(props) {
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const handleSubmitForm = async () => {
    // Show the spinner
    props.onAppStateChanged({
      isLoading: true,
    });

    try {
      const patientData = find(props.patients, { patientId: selectedPatientId });
      const notYourPetResponseStatus = await requests.submitNotYourPetData(props.clinicId, patientData.patientName, patientData.species);
      if (notYourPetResponseStatus === 204) {
        if (!!props.onAfterSubmit) {
          props.onAfterSubmit();
        }
      } else {
        throw new Error("Error submitting form");
      }
    } catch (e) {
      console.error(e);
      setShowSubmitError(true);
    } finally {
      props.onAppStateChanged({
        isLoading: false,
      });
    }
  };

  if (showSubmitError) {
    return (
      <AppError
        errorMessage="Uh oh! There was an error submitting your request. Please try again later."
      />
    );
  }

  return (
    <div>
      <h4 className={styles.formHeader}>Not your pet?</h4>
      <div className={styles.formPrompt}>
        Select the pet that is not yours
      </div>
      <div className="form">
        <div className={classnames(styles.patientTiles, styles.formContent)}>
            {props.patients.map(patientData => (
              <div
                key={`patient-tile-${patientData.PatientId}`}
                className="padding-lg"
              >
                <PatientTile
                  patient={patientData}
                  hideOffers
                  small
                  onClick={() => setSelectedPatientId(patientData.patientId)}
                  selected={selectedPatientId === patientData.patientId}
                />
              </div>
            ))}
        </div>

        <div className="text-right">
          <button
            className="et-btn small"
            onClick={handleSubmitForm}
            disabled={!selectedPatientId}
          >
            Notify Greenline Pet
          </button>
        </div>
      </div>
    </div>
  );
}

NotYourPetForm.propTypes = {
  clinicId: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func,
  patients: PropTypes.object.isRequired,
  onAppStateChanged: PropTypes.func.isRequired,
};
