import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import Confetti from "react-confetti";
import flatMap from "lodash/flatMap";
import styles from "./DashboardPage.module.scss";
import AppError from "../components/AppError";
import AddPetFormSubmittedDisplay from "../components/AddPetFormSubmittedDisplay";
import NotYourPetForm from "../forms/NotYourPetForm";
import Modal from "../components/Modal";
import PatientTile from "../components/PatientTile";
import RequestPetAdditionForm from "../forms/RequestPetAdditionForm";
import SpinningWheel from "../components/SpinningWheel";
import WatermarkBackground from "../components/WatermarkBackground";
import * as requests from "../utils/requests";

import { windowSizeContext } from "../App";
const PET_ADDED_FORM_TYPE = "PET_ADDED_FORM_TYPE";
const PET_REMOVED_FORM_TYPE = "PET_REMOVED_FORM_TYPE";

export default function DashboardPage({ appState, onAppStateChanged }) {
  const context = useContext(windowSizeContext) || {};
  const [clientOffers, setClientOffers] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [didShowAnimation, setDidShowAnimation] = useState(false);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const [today, setToday] = useState("");
  const [showManagePetsModal, setShowManagePetsModal] = useState(false);
  const [submittedManagePetsFormType, setSubmittedManagePetsFormType] = useState(null);
  const { clinicId, petOwnerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const lastShown = localStorage.getItem("confetti");
    const now = new Date();
    const todayDate = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
    if (lastShown && todayDate === lastShown) {
      setDidShowAnimation(true);
    } else {
      setShowAnimation(true);
      setToday(todayDate);
    }

  }, []);

  useEffect(() => {
    handleLoadClientOffers();
  }, [clinicId]);

  const handleLoadClientOffers = async () => {
    // Show the spinner
    setIsLoadingOffers(true);
    if (didShowAnimation) {
      onAppStateChanged({
        loading: true,
      });
    }
    try {
      const clientOffersResponse = await requests.loadClientOffers(clinicId);
      if (clientOffersResponse.Status && clientOffersResponse.Status === 500) {
        throw new Error("Error loading client offers: ", clientOffersResponse.Detail);
      }
      if (clientOffersResponse.length === 0) {
        // Client doesn't have any pets, navigate to the coupon library to show the national offers
        navigate(`/app/clinic/${clinicId}/user/${petOwnerId}/offers/`);
      }

      setClientOffers(clientOffersResponse);
    } catch (e) {
      console.error(e);
      setShowError(true);
    } finally {
      setIsLoadingOffers(false);
      onAppStateChanged({
        isLoading: false,
      });
    }
  };

  const handleConfettiEnded = () => {
    if (isLoadingOffers) {
      onAppStateChanged({
        loading: true,
      });
    }
    const now = new Date().getTime();
    localStorage.setItem("confetti", today);
    setDidShowAnimation(true);
    setShowAnimation(false);
  }

  const handleSpinWheelComplete = () => {
    setShowConfetti(true);
    setTimeout(() => {
      handleConfettiEnded();
    }, 5000);
  }

  const handleNavigateToPatientOffers = (patientId) => {
    navigate(`/app/clinic/${clinicId}/user/${petOwnerId}/offers?patientId=${patientId}`);
  };

  const handleNavigateToNationalOffers = () => {
    navigate(`/app/clinic/${clinicId}/user/${petOwnerId}/offers/`);
  }

  const handleViewAllOffersFromManagePetsModal = () => {
    const firstPatientOfferId = clientOffers[0].patientOffers[0].patientId;
    navigate(`/app/clinic/${clinicId}/user/${petOwnerId}/offers?patientId=${firstPatientOfferId}&showAllOffers=true`);
  };

  const closeManagePetsModal = () => {
    setShowManagePetsModal(false);
    setSubmittedManagePetsFormType(null);
  };

  if (!clientOffers) {
    if (showError) {
      return (
        <AppError
          errorMessage="Uh oh! There was an error loading your offers. Please try again later."
        />
      );
    }
    return null;
  }

  return (
    <WatermarkBackground>
      <div className="flex-1 flex-column height-100">
        <div className={styles.dashboardPageContent}>
          <div className={classnames("margin-top-md", styles.welcomePrompt)}>Welcome</div>
          <div className={styles.welcomeFullName}>{localStorage.getItem("userFullName")}!</div>
          {showAnimation && !didShowAnimation ? (
              <>
                <div className={styles.banner}>
                  <span className={styles.loadingBanner}>Loading Personalized Offers</span>
                </div>
                <div className={styles.spinnerWheelIntro}>
                  <SpinningWheel onCompleted={handleSpinWheelComplete} />
                </div>
              </>
          ) : (
            <>
              <div className={styles.banner}>
                <span className={styles.choosePetPrompt}>Choose Your Pet</span>
              </div>
              <div className={classnames("flex-1", styles.patientTiles)}>
                {flatMap(clientOffers.patientOffers, patientData => (
                  <div key={`patient-tile-${patientData.patientId}`}>
                    <PatientTile
                      patient={patientData}
                      onClick={() => patientData.offers.length === 0 ? handleNavigateToNationalOffers() : handleNavigateToPatientOffers(patientData.patientId)}
                    />
                  </div>
                ))}
              </div>

              <div className="flex-row flex-center padding-xl">
                <div
                  className="link"
                  onClick={() => setShowManagePetsModal(true)}
                >
                  Manage Pets
                </div>
              </div>
            </>
          )}
          {showConfetti && (
              <Confetti
                  width={context.windowWidth}
                  drawShape={ctx => {
                    ctx.clearRect(0,0, ctx.width, ctx.height);
                    ctx.font = "30px 'Font Awesome 6 Pro'";
                    ctx.fillText('\uF5D7',0,0);
                  }}
                  numberOfPieces={600}
                  recycle={false}
                  tweenDuration={20000}
              />
          )}
        </div>

        <Modal
          show={showManagePetsModal}
          headerText={!!submittedManagePetsFormType ? "Information Submitted" : "Manage Your Pets"}
          onClose={closeManagePetsModal}
          showFullCloseButton={submittedManagePetsFormType === PET_REMOVED_FORM_TYPE}
        >
          {!!submittedManagePetsFormType ? (
            <div>
              {submittedManagePetsFormType === PET_ADDED_FORM_TYPE ? (
                <AddPetFormSubmittedDisplay
                  onViewAllOffersClicked={handleViewAllOffersFromManagePetsModal}
                />
              ) : (
                <span>An email has been sent to the support team with your request.</span>
              )}
            </div>
          ) : (
            <div>
              <NotYourPetForm
                clinicId={clinicId}
                onAfterSubmit={() => setSubmittedManagePetsFormType(PET_REMOVED_FORM_TYPE)}
                patients={clientOffers.patientOffers}
                onAppStateChanged={onAppStateChanged}
              />
              <hr />
              <RequestPetAdditionForm
                clinicId={clinicId}
                onAfterSubmit={() => setSubmittedManagePetsFormType(PET_ADDED_FORM_TYPE)}
                onAppStateChanged={onAppStateChanged}
              />
            </div>
          )}
        </Modal>
      </div>
    </WatermarkBackground>
  );
}
