const getBaseApiUrl = () => {
  const isDevEnv = window.location.host === "localhost:3000";
  if (isDevEnv) {
    return "https://zealous-coast-0ce72b410.3.azurestaticapps.net/api";
  }
  return "/api";
};

export const loadFeatureFlags = async () => {
  const requestParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/features/`, requestParameters);
  return await response.json();
};

export const loadBannerAd = async () => {
  const requestParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notifications/banners`, requestParameters);
  return await response.json();
}

// Credentials is an object that must contain either:
//   1. RemoteClientDisplayId, ClientLastName, clinicGuid, and origin="qr"
//   2. Only petownerGuid and origin="email"
export const login = async (credentials) => {
    const requestParameters = {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        }
    };
    const response = await fetch(`${getBaseApiUrl()}/v1/login/token`, requestParameters);
    return await response.json();
};

export const loadClinic = async (clinicId) => {
  const requestParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/clinics/${clinicId}`, requestParameters);
  return await response.json();
};

export const loadClientOffers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const petOwnerId = localStorage.getItem("petOwnerId");
    const clinicGuid = localStorage.getItem("clinicGuid");

    const requestParameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    };
    const response = await fetch(`${getBaseApiUrl()}/v1/offers/clientoffers?petOwnerId=${petOwnerId}&clinicGuid=${clinicGuid}`, requestParameters);
    return await response.json();
};

export const loadNationalOffersForClinic = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const clinicGuid = localStorage.getItem("clinicGuid");

    const requestParameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    };
    const response = await fetch(`${getBaseApiUrl()}/v1/offers/nationaloffers?clinicGuid=${clinicGuid}`, requestParameters);
    return await response.json();
};

export const loadSurvey = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const petOwnerId = localStorage.getItem("petOwnerId");

  const requestParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/survey?petOwnerId=${petOwnerId}`, requestParameters);
  return await response.json();
};

export const submitSurvey = async (surveyId, surveyResponses) => {
  const accessToken = localStorage.getItem("accessToken");
  const petOwnerId = localStorage.getItem("petOwnerId");

  const requestBody = JSON.stringify({
    petOwnerId,
    surveyId,
    results: surveyResponses,
    responseDate: new Date().toISOString(),
  });

  const requestParameters = {
    method: "POST",
    body: requestBody,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/survey/results`, requestParameters);
  return response.status;
};

export const submitNotYourPetData = async (petName, species) => {
  const accessToken = localStorage.getItem("accessToken");
  const petOwnerId = localStorage.getItem("petOwnerId");
  const clinicGuid = localStorage.getItem("clinicGuid");

  const requestBody = JSON.stringify({
    clinicGuid,
    petOwnerId,
    reasonType: "remove",
    petName,
    species,
  });

  const requestParameters = {
    method: "POST",
    body: requestBody,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/managepets`, requestParameters);
  return response.status;
};

export const submitRequestPetAdditionData = async (petName, species) => {
  const accessToken = localStorage.getItem("accessToken");
  const petOwnerId = localStorage.getItem("petOwnerId");
  const clinicGuid = localStorage.getItem("clinicGuid");

  const requestBody = JSON.stringify({
    clinicGuid,
    petOwnerId,
    reasonType: "add",
    petName,
    species,
  });

  const requestParameters = {
    method: "POST",
    body: requestBody,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/managepets`, requestParameters);
  return response.status;
};
